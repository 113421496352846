<template>
  <div>
    <v-card
      v-if="!$root.mobile"
      elevation-0
      flat
      class="rounded-lg transparent"
    >
      <v-toolbar
        color="white"
        class=" mb-3 rounded-lg"
        style="box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);"
      >
        <v-text-field
          v-model="search"
          clearable
          flat
          dense
          single-line
          solo
          hide-details
          prepend-inner-icon="mdi-magnify"
          :label="$t('MF.search') + ' ' + $t('MENU.second_opinion')"
          style="width:100%"
        ></v-text-field>
      </v-toolbar>
      <div>
        <v-data-table
          class="rounded-lg"
          :headers="headers"
          :items="refs"
          :search="search"
          :loading="loadingStatus"
          :locale="this.$root.lang"
          :loading-text="$t('tLoading')"
        >
          <template v-slot:item.owner.first_name="{ item }">
            <div class="d-flex align-items-center">
              <span
                v-if="item.owner.avatar"
                class="symbol symbol-circle symbol-45 symbol-light-primary mr-3"
              >
                <img
                  alt="Pic"
                  :src="item.owner.avatar.data.thumbnails[0].url"
                />
              </span>
              <span
                v-else
                class="symbol symbol-circle symbol-45 symbol-light-primary mr-3"
              >
                <span
                  class="symbol-label font-size-h5 font-weight-bold"
                  style="text-transform: uppercase"
                  >{{ item.owner.first_name.charAt(0) }}</span
                >
              </span>
              <div class="d-flex flex-column text-left">
                <span class="text-dark-75 font-weight-bolder font-size-lg"
                  >{{ item.owner.first_name }} {{ item.owner.last_name }}</span
                >
              </div>
            </div>
          </template>

          <template v-slot:item.medical_file.full_name="{ item }">
            <div class="d-flex align-items-center">
              <span
                v-if="item.medical_file.owner.avatar"
                class="symbol symbol-circle symbol-45 symbol-light-primary mr-3"
              >
                <img
                  alt="Pic"
                  :src="item.medical_file.owner.avatar.data.thumbnails[0].url"
                />
              </span>
              <span
                v-else
                class="symbol symbol-circle symbol-45 symbol-light-primary mr-3"
              >
                <span
                  class="symbol-label font-size-h5 font-weight-bold"
                  style="text-transform: uppercase"
                  >{{ item.medical_file.full_name.charAt(0) }}</span
                >
              </span>
              <div class="d-flex flex-column text-left">
                <span class="text-dark-75 font-weight-bolder font-size-lg">{{
                  item.medical_file.full_name
                }}</span>
              </div>
            </div>
          </template>

          <template v-slot:item.reason="{ item }">
            <span class="text-dark-75 font-weight-bold"
              >{{ item.reason.substring(0, 50) }} ...
            </span>
            <a
              class="btn btn-icon btn-light-success btn-circle btn-sm ml-2"
              id="fullreason"
            >
              <i class="flaticon-more-v2"></i>
            </a>
            <b-popover target="fullreason" triggers="hover" placement="right">
              <template v-slot:title>{{ $t("REF.header.reason") }}</template>
              {{ item.reason }}
            </b-popover>
          </template>

          <template v-slot:item.created_on="{ item }">
            <div class="d-flex align-items-center">
              <span class="text-dark-75 font-weight-bold font-size-sm mr-2">{{
                item.created_on | moment("add", "5 days", "dddd, MMMM Do YYYY")
              }}</span>
              <span class="text-dark-75 font-weight-light font-size-sm mr-2">{{
                item.created_on
                  | moment("add", "5 days")
                  | moment("from", "now", true)
              }}</span>
            </div>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn
              color="rgba(165, 203, 239, 0.20)"
              elevation="0"
              rounded
              large
              @click="openmf(item)"
            >
              <unicon
                name="file-medical-alt"
                fill="#0971CE"
                height="20"
              ></unicon>
              <span class="primary--text">Medical File</span>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <div v-else>
      <div class="customnav ">
        <v-text-field
          v-model="search"
          append-icon="search"
          :label="$t('APPOINTMENTS.APP.search')"
          filled
          color="primary"
          background-color="rgba(165, 203, 239, 0.42)"
          rounded
          hide-details
          dense
        />
      </div>
      <v-list class="transparent">
        <v-list-item v-for="item in refs" class="listitem mb-2" :key="item.id">
          <v-list-item-avatar>
            <v-avatar color="primary" size="40">
              <v-img
                v-if="item.owner.avatar != null"
                :src="$root.apiURL + 'assets/' + item.owner.avatar.private_hash"
              ></v-img>
              <div
                v-else
                style="text-transform: uppercase;width:100%"
                class="white--text font-size-h4"
              >
                {{ item.owner.first_name.charAt(0) }}
              </div>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              style="font-size:1.1rem; font-weight:500"
              class="mb-2"
            >
              {{ item.owner.first_name }} {{ item.owner.last_name }}
            </v-list-item-title>
            <v-list-item-subtitle class="mb-2">
              {{ $t("REF.header.patient") }} {{ item.medical_file.full_name }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ $t("REF.header.expire") }}
              <span class="text-dark-75 font-weight-bold font-size-sm mr-2">{{
                item.created_on | moment("add", "5 days", "MMM Do YYYY")
              }}</span>
              <span class="text-dark-75 font-weight-light font-size-sm mr-2">{{
                item.created_on
                  | moment("add", "5 days")
                  | moment("from", "now", true)
              }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              color="rgba(165, 203, 239, 0.20)"
              elevation="0"
              small
              fab
              @click="openmf(item)"
            >
              <unicon
                name="file-medical-alt"
                fill="#0971CE"
                height="20"
              ></unicon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>

    <MF
      :dialog="mfdialog"
      :consultID="cID"
      @closeClicked="
        mfdialog = false;
        cID = null;
        $root.title = $t('MENU.second_opinion');
      "
    />
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import MF from "@//view/pages/app/comp/mf.vue";

export default {
  name: "referral",
  components: { MF },

  data() {
    return {
      search: "",
      error: "",
      headers: [
        {
          text: this.$t("REF.header.from"),
          align: "left",
          sortable: true,
          value: "owner.first_name"
        },
        {
          text: this.$t("REF.header.patient"),
          value: "medical_file.full_name"
        },
        { text: this.$t("REF.header.reason"), value: "reason" },
        { text: this.$t("REF.header.expire"), value: "created_on" },
        { text: this.$t("REF.header.action"), value: "action", align: "right" }
      ],
      refs: [],
      loadingStatus: true,
      mfdialog: false,
      cID: null
    };
  },
  computed: {},
  mounted() {
    this.$root.title = this.$t("MENU.second_opinion");
    this.getReferrals();
  },
  destroyed() {},
  methods: {
    openmf(mf) {
      // console.log('mf to open is',mf)
      this.cID = mf.id;
      this.$root.doctormf = mf.medical_file.id;
      this.mfdialog = true;
    },
    async getReferrals() {
      this.$moment.locale("en");

      await ApiService.get(
        "items",
        "referral?fields=id,owner.id,owner.first_name,owner.last_name,owner.avatar.data,medical_file.id,medical_file.owner.avatar.data,medical_file.full_name,reason,created_on&filter[recipient]=" +
          this.$root.docID +
          "&filter[created_on][gte]=" +
          this.$moment()
            .subtract(5, "d")
            .format()
      )
        .then(({ data }) => {
          this.refs = data.data;
          this.loadingStatus = false;
          this.$moment.locale(localStorage.language);
        })
        .catch(error => {
          this.error = error;
        });
    }
  }
};
</script>
